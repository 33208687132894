<template>
  <base-form-layout
    :title="`${action} ${$tc('item.name', 1)}`"
    @submit="onSubmit"
  >
    <v-form ref="form">
      <InventoryItemEditForm v-if="!isLoading" :item="inventoryItem" />
    </v-form>
  </base-form-layout>
</template>

<script>
import InventoryItemEditForm from './components/InventoryItemEditForm'
import { INVENTORY_ITEM } from '@/models/inventory.item'
import { FormMixin } from '@/mixins/form.mixin'
import { DocumentMixin } from '@/mixins/document.mixin.js'
import { mapActions } from 'vuex'
export default {
  name: 'InventoryItemEdit',
  components: {
    InventoryItemEditForm
  },
  mixins: [FormMixin, DocumentMixin],
  props: {
    parentId: {
      type: String,
      default: ''
    },
    inventoryItemId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      parent: null,
      inventoryItem: INVENTORY_ITEM(this.parentId),
      publish: this.$route.query.publish,
      isLoading: true
    }
  },

  computed: {
    action() {
      return this.$t(
        this.publish
          ? 'actions.publish'
          : this.inventoryItemId
          ? 'actions.edit'
          : 'actions.new'
      )
    }
  },
  async created() {
    if (this.parentId) {
      this.parent = await this.fetchInventoryItem(this.parentId)
      this.mergeAttributes()
    }
    if (this.inventoryItemId)
      this.inventoryItem = await this.fetchInventoryItem(this.inventoryItemId)
    this.isLoading = false
  },

  methods: {
    ...mapActions({
      addNotification: 'notification/add'
    }),
    async fetchInventoryItem(inventoryItemId) {
      const { data } = await this.$store.dispatch(
        'inventoryItem/fetch',
        inventoryItemId
      )
      return data
    },
    mergeAttributes() {
      const {
        category,
        ncCode,
        origin,
        specie,
        type,
        resource,
        incomingShipment
      } = this.parent.attributes.customAttributes

      Object.assign(this.inventoryItem.attributes.customAttributes, {
        category,
        ncCode,
        origin,
        specie,
        type,
        resource,
        incomingShipment
      })
    },

    async cloneItemResourceToRawProduct() {
      const newItem = { ...this.inventoryItem }
      newItem.attributes.quantity = this.inventoryItem.attributes.itemUnitsCount
      newItem.attributes.category = 'end_product'
      await this.$store.dispatch('inventoryItem/create', newItem)
      this.inventoryItem.attributes.status = 'out_stock'
    },

    async onSubmit() {
      this.beforeSubmit = false

      if (this.publish && this.inventoryItem.attributes.category === 'resource')
        await this.cloneItemResourceToRawProduct()
      else if (this.publish)
        this.inventoryItem.attributes.category = 'end_product'
      try {
        const action = this.inventoryItemId ? 'update' : 'create'
        const { data: item } = await this.$store.dispatch(
          `inventoryItem/${action}`,
          this.inventoryItem
        )
        await this.createDocument(item.id, 'Item', 'Attachment')
        this.addNotification({
          status: '200',
          color: 'success',
          time: 2000,
          messages: { title: `Successfully ${action}d` }
        })
        this.$router.go(-1)
      } catch (error) {
        if (!error.response) return
        const {
          status,
          data: { errors }
        } = error.response
        return this.addNotification({
          status,
          messages: errors,
          color: 'error',
          time: 10000
        })
      }
    }
  }
}
</script>
