import { ORGANIZATION_ID } from '@/common/config.js'

export const INVENTORY_ITEM = parentId => ({
  type: 'item',
  attributes: {
    name: '',
    orderId: '',
    facilityId: '',
    supplierId: '',
    status: 'in_stock',
    sellingPrice: 0,
    sellingPriceCurrency: '',
    costPrice: 0,
    costPriceCurrency: '',
    tax: '',
    systemNumbers: '',
    systemNumbersUnit: '',
    organizationId: ORGANIZATION_ID(),
    parentId,
    itemGroupId: null,
    category: 'raw_product',
    description: '',
    quantity: 1,
    weight: null,
    weightUnit: '',
    dimensions: { length: null, width: null, height: null, diameter: null },
    dimensionsUnit: 'mm',
    volume: null,
    volumeUnit: 'm³',
    customAttributes: {
      resource: '',
      // diameter: null,
      // diameterUnit: 'mm',
      specie: { text: 'Bigleaf Maple', value: 'Acer macrophyllum Pursh' },
      origin: ''
    }
  }
})
