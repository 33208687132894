<template>
  <div>
    <h6 class="text-h6 mb-5">{{ $t('common.details') }}</h6>

    <v-row dense>
      <v-col cols="12" md="4"
        ><BaseSelector
          v-model="item.attributes.name"
          :item-text="i => $t(`inventory.categories.${i.value}`)"
          :items="inventoryCategories"
          :label="`${$t('common.name')} (*)`"
          :placeholder="$t('inventory.categories.lumber')"
      /></v-col>

      <v-col cols="12" md="4">
        <BaseSelector
          v-model="item.attributes.customAttributes.partName"
          :item-text="i => $t(`inventory.categories.${i.value}`)"
          :items="partCategories"
          :label="$tc('item.name', 1)"
      /></v-col>

      <v-col cols="12" md="4">
        <BaseFieldInput
          v-model="item.attributes.description"
          :label="`${$t('item.description')}`"
          placeholder="Lumber 1"
      /></v-col>

      <v-col cols="12" md="6"
        ><BaseSelector
          v-model="item.attributes.customAttributes.specie"
          :items="species"
          :label="$t('item.specie')"
          :placeholder="$t('species.Sapele')"
          :item-text="item => $t(`species.${item.text}`)"
          disabled
      /></v-col>
      <v-col cols="12" md="6"
        ><BaseSelector
          v-model="item.attributes.facilityId"
          :items="facilities"
          :label="`${$t('facility.location')}`"
          :placeholder="`${$tc('facility.name', 1)} 1`"
          item-text="attributes.name"
          item-value="id"
      /></v-col>

      <v-col cols="12" md="6"
        ><BaseSelector
          v-model="item.attributes.customAttributes.type"
          :items="types"
          :label="$t('item.type')"
      /></v-col>
      <v-col cols="12" md="6"
        ><BaseSelector
          v-model="item.attributes.customAttributes.ncCode"
          :items="ncCodes"
          :label="$t('item.ncCode')"
      /></v-col>
      <!-- <v-col cols="12" md="6">
        <BaseFieldInput
          filled
          :value="item.attributes.itemUnitsInStockCount"
          :disabled="true"
      /></v-col> -->
    </v-row>

    <h6 class="text-h6 mb-5">{{ $t('item.dimensions') }}</h6>
    <v-divider class="mb-8"></v-divider>
    <!-- <ItemRawProductEditFormMeasurement :item="item" /> -->
    <ItemEditFormMeasurement :item="item" :pack-option="true" />

    <h6 class="text-h6 mb-5">{{ $tc('document.attachment', 2) }}</h6>
    <v-divider class="mb-8"></v-divider>
    <base-file-input />
  </div>
</template>

<script>
// import ItemRawProductEditFormMeasurement from '@/views/item-raw-product/components/ItemRawProductEditFormMeasurement'
import ItemEditFormMeasurement from '@/components/ItemEditFormMeasurement'
// import QuantityField from '@/components/QuantityField'
import {
  ITEM_CATEGORIES,
  INVENTORY_CATEGORIES,
  SPECIES,
  TYPES,
  NC_CODES,
  PARTS_CATEGORIES
} from '@/common/terms'
import { ORGANIZATION_ID } from '@/common/config'
export default {
  name: 'ResourceEditForm',
  components: {
    // ItemRawProductEditFormMeasurement,
    ItemEditFormMeasurement
    // QuantityField
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      inventoryCategories: INVENTORY_CATEGORIES,
      partCategories: PARTS_CATEGORIES,
      itemCategories: ITEM_CATEGORIES,
      species: SPECIES,
      types: TYPES,
      ncCodes: NC_CODES,
      facilities: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.facilities = await this.fetchFacilities()
    },
    async fetchFacilities() {
      return await this.$store.dispatch('facility/filter', {
        organizationId: ORGANIZATION_ID()
      })
    }
  }
}
</script>
